import { render, staticRenderFns } from "./home.vue?vue&type=template&id=724e17a7&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBannerCarousel: require('/opt/buildhome/repo/components/Home/BannerCarousel.vue').default,CursosCarousel: require('/opt/buildhome/repo/components/Cursos/Carousel.vue').default,BlogSectionBlogNoticia: require('/opt/buildhome/repo/components/Blog/SectionBlogNoticia.vue').default,HomeContato: require('/opt/buildhome/repo/components/Home/Contato.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFlex,VIcon,VImg,VLayout})
