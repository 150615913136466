//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { instMeta } from '@/data/meta-inst'
import cursos from '@/data/cursos.js'

export default {
	data() {
		return {
			showVideo: false,
			cursos,
			// isActive: false,
		}
	},
	head() {
		return instMeta()
	},
	computed: {
		cursosLivres() {
			return this.cursos.filter((c) => c.categoria === 'Curso')
		},
		cursosPos() {
			return this.cursos.filter((c) => c.categoria === 'Pós-graduação')
		},
	},
	methods: {
		scrollIntoView(id) {
			const yOffset = -60
			const element = document.querySelector(id)
			const y =
				element.getBoundingClientRect().top + window.pageYOffset + yOffset

			window.scrollTo({ top: y, behavior: 'smooth' })
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			setTimeout(() => {
				if (to.hash) {
					vm.scrollIntoView(to.hash)
				}
			}, 500)
		})
	},
	beforeRouteUpdate(to, from, next) {
		setTimeout(() => {
			if (to.hash) {
				this.scrollIntoView(to.hash)
			}
		}, 500)
		next()
	},
	mounted() {
		if (location.hash) {
			setTimeout(() => {
				if (location.hash) {
					this.scrollIntoView(location.hash)
				}
			}, 500)
		}
		setTimeout(() => {
			new RDStationForms(
				'saiba-mais-sobre-nossos-cursos-68d48e13ff33a94d5932',
				'UA-228576147-1'
			).createForm()
		}, 500)
	},
}
